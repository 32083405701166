<template>
  <v-container
    :class="{
      'title-item-dark': $vuetify.theme.dark,
    }"
    fluid
  >
    
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/usuarios" />
        </v-col>
      </v-row>
    </v-card-actions>
    <usuarios-campos
      :carregando-salvar="carregandoSalvar"
      :validacao-formulario="validacaoFormulario"
      :usuario="usuario"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import usuarios from "@/api/usuarios";
//import _ from 'lodash'

export default {
  components: {
    UsuariosCampos: () => import("../usuarios/UsuariosCampos"),
  },

  data() {
    return {
      carregandoSalvar: false,
     
      validacaoFormulario: {},
      usuario: {},
    };
  },

  mounted() {
    this.buscar();
  },

  methods: {
    async buscar() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await usuarios.buscar(this.$route.params.id);
        var usuario = {
          nome: resposta.data.data.pessoa.nome,
          id: resposta.data.data.id,
          empresa_id: resposta.data.data.empresa_id,
          login: resposta.data.data.login,
          email: resposta.data.data.email,
          ativo: resposta.data.data.ativo,
          grupos: resposta.data.data.grupos.map((grupo) => grupo.id),
        };
        this.usuario = usuario;
      } catch (e) {
        this.$router.push("/usuarios", () =>
          this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) })
        );
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async salvar(usuario) {
      this.carregandoSalvar = true;

      try {
        await usuarios.atualizar(usuario.id, usuario);

        this.$router.push("/usuarios", () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_editar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = e.response.data.erros;
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
        return;
      } finally {
        this.carregandoSalvar = false;
      }
    },
  },
};
</script>
